.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 100%;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logout{
  float:right;
}

.header_username {
  display: flex;
  height: 2rem;
  margin: 0 auto;
  /* width: 10px; */
  float:right;
}
/* .header_username > li {
  width: 25%;
} */
/*全てのリスト・リンク共通*/
.header_username li {
  list-style: none;
  position: relative;
  margin-right: 15px;
}
.header_username li a {
  background-color: #00B4C6;
  border: none;
  color: white;
  display: block;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  text-decoration: none;
  width: 100%;
}

/*子階層以降共通*/
.header_username li li {
  height: 0;
  overflow: hidden;
  transition: .5s;
  width: 110%;
}
.header_username li li a {
  /* border: none; */
  border-top: 1px solid #eee;
}
.header_username li:hover > ul > li {
  height: 2rem;
  overflow: visible;
}

.button{
  background-color: #00B4C6;
  color: white;
  padding: 10px;
  margin: 10px 0px 10px 15px;
	border-radius: 4px;
  border: 0px none;
  cursor: hand;
  cursor:pointer;
  text-decoration: none;
  width: 75px;
  text-align: center;
  font-family: "メイリオ";
  float:left;
}
.button:hover{
  opacity:0.8;
}
.button_center{
  background-color: #00B4C6;
  color: white;
  padding: 10px;
  margin: 10px 0px 10px 15px;
	border-radius: 4px;
  border: 0px none;
  cursor: hand;
  cursor:pointer;
  text-decoration: none;
  width: 75px;
  text-align: center;
  font-family: "メイリオ";
  float:center;
}
.button_center:hover{
  opacity:0.8;
}
.center_wrapper{
  text-align:center;
}

.button2{
  background-color: #c8f0f0;
  padding: 5px 2px;
  margin-left: 15px;
	border-radius: 4px;
  border: 0px none;
  cursor: hand;
  cursor:pointer;
  text-decoration: none;
  width: 75px;
  text-align: center;
  font-family: "メイリオ";
  font-size: 11px;
  font-weight: bold;
}
.button2:hover{
  opacity:0.8;
}
.back_link{
  background-color: #00B4C6;
  color: white;
  padding: 12px 29px;
  margin-left: 15px;
	border-radius: 4px;
  border: 0px none;
  cursor: hand;
  cursor:pointer;
  text-decoration: none;
  width: 75px;
  height: 500px;
  text-align: center;
  font-family: "メイリオ";
  font-size: 12px;
  font-weight: bold;
  font-size: 18px;
}
.back_link:hover{
  opacity:0.8;
}

.regist_button_right_1{
  float:right;
  background-color: #00B4C6;
  color: white;
  padding: 8px;
	border-radius: 4px;
  border: 0px none;
  cursor: hand;
  cursor:pointer;
  text-decoration: none;
  width: 90px;
  text-align: center;
  font-weight: bold;
}
.regist_button_right_1:hover{
  opacity:0.8;
}

.regist_button_right_2{
  float:right;
  background-color: #00B4C6;
  color: white;
  padding: 13px;
	border-radius: 4px;
  border: 0px none;
  cursor: hand;
  cursor:pointer;
  text-decoration: none;
  width: 100px;
  text-align: center;
  font-weight: bold;
}
.regist_button_right_2:hover{
  opacity:0.8;
}

.regist_button{
  background-color: #00B4C6;
  color: white;
  padding: 13px;
	border-radius: 4px;
  border: 0px none;
  cursor: hand;
  cursor:pointer;
  text-decoration: none;
  width: 90px;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}
.regist_button:hover{
  opacity:0.8;
}

.regist_button_marginleft{
  background-color: #00B4C6;
  color: white;
  padding: 13px;
	border-radius: 4px;
  border: 0px none;
  cursor: hand;
  cursor:pointer;
  text-decoration: none;
  width: 90px;
  text-align: center;
  font-weight: bold;
  margin-left: 20px;
  font-size: 18px;
}
.regist_button:hover{
  opacity:0.8;
}

.cancel_button{
  background-color: #FFFFFF;
  color: #00B4C6;
  padding: 0.9em 1.7em;
	border-radius: 4px;
  border: 2px solid;
  border-color: #00B4C6;
  cursor: hand;
  cursor:pointer;
  text-decoration: none;
}

.link_style_button{
  cursor: pointer;
  border: none;
  background: none;
  color: #00B4C6;
  text-decoration: underline;
  font-family: "メイリオ";
  font-weight: bold;
  font-size: 16px;
}

.file_upload_button_label{
  background-color: #00B4C6;
  color: white;
  padding: 0.9em 1.7em;
	border-radius: 4px;
  border: 0px none;
  cursor: hand;
  cursor:pointer;
  width: 35px;
  height: 20px;
}
.file_upload_button_label:hover{
  opacity:0.8;
}

.file_upload_button{
  display: none;
}

.textbox{
  width: 300px;
  border: 1px solid #bebebe;
	border-radius: 3px;
  box-sizing: border-box;
  padding: 0.6em 0.2em 0.6em 0.6em;
  margin: 10px 10px 10px 20px;
  background-color: #ecf6f7;
}
.textbox_large{
  width: 400px;
  border: 1px solid #bebebe;
	border-radius: 3px;
  box-sizing: border-box;
  padding: 0.6em 0.2em 0.6em 0.6em;
  margin: 10px 10px 10px 20px;
  background-color: #ecf6f7;
}

.selectbox_year{
  width: 60px;
  border: 1px solid #bebebe;
	border-radius: 3px;
  box-sizing: border-box;
  padding: 0.2em 0.4em 0.2em 0em;
  margin: 10px 3px 10px 3px;
  background-color: #ecf6f7;
}
.selectbox_monthday{
  width: 50px;
  border: 1px solid #bebebe;
	border-radius: 3px;
  box-sizing: border-box;
  padding: 0.2em 0em 0.2em 0em;
  margin: 10px 3px 10px 3px;
  background-color: #ecf6f7;
}

.min-text{
  font-size: small;
}

ul li {
  list-style: none;
}
ul.list{
  /* width: 800px; */
  margin: 0 auto;
}
li.list{
  margin-top: 10px;
}
/* .li_left{
  width: 150px;
  word-wrap: break-word;
  float:left;
  margin-right: 10px;
} */
.test{
  background-color: yellow;
}


.header{
  height: 100px;
  background-color: #FAFAFA;
  min-width: 1000px;
  margin: 0px 150px;
  line-height: 25px;
  font-family: "メイリオ";
}
.header_link:link, .header_link:visited, .header_link:hover, .header_link:active{
  color: #00B4C6;
  text-decoration: none;
  margin-left: 20px;;
}
.overall{
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  min-width: 1500px;
}
.overall_table{
  width: 100%;
}
:root {
  --amplify-primary-color: #00B4C6;
  --amplify-primary-tint: #00B4C6;
  --amplify-primary-shade: #00B4C6;
}

.center{
  text-align: center
}

/* .center_parents{
  display: flex;
  justify-content: center;
} */

.password_attention{
  color: red;
}

.lead_icon{
  float:left;
  width: 10%;
  min-width: 80px;
  padding-left: 2%;

}


table {
  table-layout: fixed;
  width: 100%;
  font-family: "メイリオ";
}

table td {
  word-break: break-all;
  /* overflow-wrap : break-word; */
}

.table_border{
  border-collapse: collapse;
  border-spacing: 0;
}

.table_border_updown_thtd_left{
  border-collapse: collapse;
  border-top: 1px solid rgb(200, 200, 200);
  border-bottom: 1px solid rgb(200, 200, 200);
  background-color: rgb(240, 240, 240);
  border-spacing: 0;
  width: 140px;
  padding-left: 10px;
  font-weight: bold;
}
.table_border_updown_thtd_left_number{
  border-collapse: collapse;
  border-top: 1px solid rgb(200, 200, 200);
  border-bottom: 1px solid rgb(200, 200, 200);
  border-right: 2px solid white;
  background-color: rgb(240, 240, 240);
  border-spacing: 0;
  padding-left: 8px;
  font-weight: bold;
  height: 60px;
}
.table_border_updown_thtd_left_titlenumber{
  border-collapse: collapse;
  border-spacing: 0;
  width: 40px;
}
.table_border_updown_thtd_left_title{
  width: 400px;
}
.table_border_up_thtd_left{
  border-collapse: collapse;
  border-top: 1px solid rgb(200, 200, 200);
  background-color: rgb(240, 240, 240);
  border-spacing: 0;
  width: 90px;
  padding-left: 10px;
}
.table_border_down_thtd_left{
  border-collapse: collapse;
  border-bottom: 1px solid rgb(200, 200, 200);
  background-color: rgb(240, 240, 240);
  border-spacing: 0;
  width: 90px;
  padding-left: 10px;
  font-weight: bold;
}
.table_username_title{
  width: 250px;
}
.table_short_title{
  min-width: 120px;
}
.table_short_title_2{
  min-width: 200px;
}
.table_short_title_button{
  width: 100px;
}
.table_veryshort_title{
  min-width: 50px;
}
.status_table_left{
  min-width: 300px;
}
.status_table_right{
  min-width: 150px;
}
.table_border_updown_thtd_right{
  border-collapse: collapse;
  border-top: 1px solid rgb(200, 200, 200);
  border-bottom: 1px solid rgb(200, 200, 200);
  border-spacing: 0;
}

.table_border_up_thtd_right{
  border-collapse: collapse;
  border-top: 1px solid rgb(200, 200, 200);
  border-spacing: 0;
}
.table_border_down_thtd_right{
  border-collapse: collapse;
  border-bottom: 1px solid rgb(200, 200, 200);
  border-spacing: 0;
}
.table_button2_width{
  width: 90px;
}
.table_regist_width{
  width: 90px;
  margin-left: 30px;
}
.require{
  color: #00B4C6;
  text-align: right;
  width: 50px;
  padding-right: 10px;
  font-weight: bold;
  font-size: 12px;
}

.table_border_thtd{
  border: 1px black solid;
}

.width_100{
  width: 100%;
}

.title_text{
  font-weight: bold;
  font-size: 20px;
}

.header_title{
  font-weight: bold;
  font-size: xx-large;
  float:left;
  padding-left: 2%;
}

.body{
  margin: 0px 200px;
  min-width: 1000px;
  font-family: "メイリオ";
}

.font-meiryo{
  font-family: "メイリオ";
}

.accessgroup_table_height{
  height: 50px;
}

.li_hotels{
  list-style-type: none;
}

.doorlock_table_updown_center{
  line-height: 60px;
}

.tr_height_settingdoorlock{
  height: 80px;
}
.td_width_settingdoorlock_button{
  width: 120px;
}
.td_width_settingdoorlock_title{
  width: 160px;
}
.td_width_settingdoorlock_textbox{
  width: 330px;
}

.tr_height_settinggateway{
  height: 60px;
}

.tr_height_status{
  height: 60px;
}

.table_border_white_up_thtd_left{
  border-collapse: collapse;
  border-top: 2px solid white;
  border-bottom: 1px solid rgb(200, 200, 200);
  border-left: 2px solid white;
  background-color: rgb(240, 240, 240);
  border-spacing: 0;
  width: 140px;
  padding-left: 10px;
  font-weight: bold;
}

.table_border_white_down_thtd_left{
  border-collapse: collapse;
  border-top: 1px solid rgb(200, 200, 200);
  border-bottom: 2px solid white;
  border-left: 2px solid white;
  background-color: rgb(240, 240, 240);
  border-spacing: 0;
  width: 140px;
  padding-left: 10px;
  font-weight: bold;
}
.table_border_white_updown_thtd_left{
  border-collapse: collapse;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  border-left: 2px solid white;
  background-color: rgb(240, 240, 240);
  border-spacing: 0;
  width: 140px;
  padding-left: 10px;
  font-weight: bold;
}

/* .pagination {
  display: flex;
  margin: auto;
}
.pagination__previous > a{
  font-size: 0.7rem;
}
.pagination__next > a{
  font-size: 0.7rem;
}
.pagination__disabled{
  display: none;
}
.pagination > li{
  margin: 0 12px;
}

.pagination > li.active > a::before{
  font-weight: 700;
  background-color: red;
}
*/
.pagination > li.active{
  background-color: #c8f0f0;

}


.pagination > li > a{
  position: relative;
  font-size: 17px;
  width: 24px;
  height: 24px;
  outline: none;
  z-index: 100;
  cursor: pointer;
}
/*
.pagination > li > a ::before{
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  z-index: -100;
}
.pagination > li > a :hover{
  background-color: blue;
}
.pagination > li > a :hover::before{
  background-color: blue;
} */


.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  gap: 20px 6px;
}


.page-item,
.page-link {
  display: inline-flex;
  align-items: center;
  border-radius: 30px;
  justify-content: center;
  font-size: 16px;
  height: 40px;
  width: 40px;
}

.form-section-header .header {
  color: var(--header-color);
  font-size: var(--header-size);
  font-weight: 700;
  margin-bottom: 0.75rem;
  background-color: red;
}